export const useStoryblokOptions = (): ComputedRef<{ language: string; version: string; }> => {
  const runtimeConfig = useRuntimeConfig();
  const locale = useNuxtApp().$i18n.locale as unknown as { value: string };
  const route = useRoute();
  const isStoryblokDraft = !!route.query._storyblok;
  const isStoryblokPublished = !!route.query._storyblok_published;

  const requestOptions = computed(() => {
    return {
      language: locale.value,
      version:
        isStoryblokDraft || (!isStoryblokPublished && runtimeConfig.public.DEV)
          ? "draft"
          : "published",
    }
  });

  return requestOptions;
};
